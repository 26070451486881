* {
box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');
body {
margin: 0;
font-family: 'Krona One', sans-serif;
}
.App {

width: 100%;
display: flex;
justify-content: center;
background-image: linear-gradient(rgb(45, 202, 241),60%, rgb(255, 238, 88));
}
.box-one {
padding-left: 50px;
margin-left: 15px;
margin-right: 8;
overflow: hidden;
width: 350px;
height: 400px;
text-align: center;
}
.b1 {
background-color: rgba(252, 41, 76, 0.5);
display: flex;
flex-direction: column;

font-size: 20px;
color: #fff;

margin-top: 120px;
overflow: hidden;
padding: 40px 20px 0px 0px;
height: 220px;
width: 220px;
transform: rotate(
45deg
);

}
span.b1-content {
transform: rotate(
-45deg
);
position: relative;
left: 4px;
bottom: 20px;
}

.navbar>button{
font-family: 'Krona One', sans-serif;
color: white;
}
.b2{

background-color: rgba(1, 159, 199, 0.5);
display: flex;
flex-direction: column;
font-size: 20px;
color: #fff;
margin-top: 120px;
z-index: 3;
display: flex;
flex-direction: row;
justify-content: space-around;
align-content: center;
width: 100%;
backdrop-filter: blur(10px);
position: -webkit-sticky;
position: sticky;
top: 0;
padding: 15px;
}

.b3-left-right{

margin: auto;

justify-content: center;
display: flex;
/* position: relative;
bottom: 50px; */
}
.b3-left{
position: absolute;


margin-left: auto;
}
.b3-right{
z-index: 1;
margin-right: auto;

overflow: hidden;

}

.b3{
background: none;
display: flex;
overflow: hidden;
padding-bottom: 280px;

}

.about-me-content-container{
margin-top: 80px;
/* background-color: rgba(252, 41, 76, 0.5); */
display: flex;
flex-direction: column;
display: flex;
flex-direction: column;
font-size: 20px;
color: #fff;
}
.about-me-content{
font-size: medium;

margin-left: 20px;
margin-right: 20px;
}
.about-me-content>p{
margin-bottom: 2em;
}
.photo-circles{
margin-left: 20px;
margin-right: 20px;
}
.photo-me{

top: 140px;
position: relative;
z-index: 2;
width: 300px;
height: 300px;

border-radius: 50%;

}
.creative-circle-red{
top: 200px;
background-image: url('./images/books.jpeg');
/*- photo by Greg Rakozy https://unsplash.com/@grakozy-*/
background-size: 350px;
background-size: 365px;
background-position-y: 22px;
background-position-x: -60px;
position: relative;
width: 300px;
height: 300px;
margin: auto;
background-color: rgb(255, 0, 0, .5);
border-radius: 50%;
}
.creative-circle-blue{
top: 240px;
position: relative;
width: 300px;
height: 300px;
background-image: url('./images/mission-control.jpeg');
/*-photo by Sieuwert Otterloo https://unsplash.com/ -*/
background-size: 350px;
background-size: 395px;
background-position-y: 30px;
background-position-x: -65px;
margin: auto;
background-color: rgb(40, 141, 236, .5);
border-radius: 50%;
}
.projects-header{
color: white;
text-align: center;
/* letter-spacing: .7em; */
font-size: xx-large;
display: flex;
justify-content: center;
}
.b4{
padding-top: 70px;
overflow: hidden;
width: 100%;
}
.b4>h2>p{

overflow: hidden;
position: relative;
height: 50px;
width: 50px;
opacity: .8;

}
.projects-one{
background-color: rgb(235, 225, 91);
box-shadow: 10px 10px 10px  rgb(139, 134, 54);
}
.projects-two{
background-color: mediumturquoise;
box-shadow: 10px 10px 10px  rgb(54, 160, 156);
}
.projects-three{
background-color: rgb(156, 231, 42);
box-shadow: 10px 10px 10px  rgb(104, 153, 30);
}
.projects-four{
background-color: hotpink;
box-shadow: 10px 10px 10px rgb(168, 69, 119);
}
.projects-five{
background-color:orange;
box-shadow: 10px 10px 10px rgb(175, 114, 1);
}
.projects-six{
background-color: mediumturquoise;
box-shadow: 10px 10px 10px  rgb(54, 160, 156);
}

.projects-seven{
background-color: rgb(156, 231, 42);
box-shadow: 10px 10px 10px  rgb(104, 153, 30);
}
.projects-eight{
background-color: rgb(235, 225, 91);
box-shadow: 10px 10px 10px  rgb(139, 134, 54);
}
.images-background{

background: gray;
width: 1000px;
overflow-y:hidden;
overflow-x:hidden;
}
.logos{
margin-top: 80px;
margin-bottom: 40px;
margin-left: 5px;
margin-right: auto;
display: flex;
justify-content: space-around;
}
.logo-button{
display: flex;
flex-direction: column;
justify-content: space-between;
}
button:focus:not(:focus-visible) {
outline: none;
}
button{
padding-top: 10px;
padding-bottom: 10px;
width: 200px;
background: none;
border: none;
cursor: pointer;
}

label{
text-align: center;
}

.logo{
border-radius: 50%;
box-shadow: 2px 2px 10px rgb(82, 81, 81);
background: none;

transition:  box-shadow .3s;
}
.logo:active{
box-shadow: none;

}
.pick-project{
margin-top: 40px;
color: white;
text-align: center;
font-size: large;
}

.main-app-content{

padding-top: 40px;
/* display: flex;
justify-content: space-around; */
}


.projects-right{
width: 37%;
position: relative;
bottom: 20px;
}

/*--mini-phone-display for screenshot --*/
.app-border{
margin-top: 50px;
border: solid black;
border-width: 10px;
border-radius: 20px;
box-shadow:  0 0 15px rgb(46, 46, 46, .7) inset,0 0 0 5px hsl(0, 0%, 90%),10px 10px 10px rgb(46, 46, 46, .7);
text-align: center;
overflow-y: hidden;
width:310px;
height: 470px;
margin: auto;
margin-bottom: 20px;
}
.app-main{
border-width: 10px;
border-radius: 20px;
background-image: linear-gradient(orange , rgb(221, 163, 172));
box-shadow: 0px 0px 10px rgb(172, 112, 1) ;
margin-left:auto;
margin-right: auto;
margin-bottom: 20px;
padding: 10px;
}


.Parallax__background-triangles{
background-image: url("background-triangles.png");
}
.main__content{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
z-index: 2;
margin-top: 100px;
}


.content-heading{
margin: auto;
justify-content: center;
background-color: mediumspringgreen;

}
.header{
color: whitesmoke;
letter-spacing: .4em;
display: flex;
margin: 10px 0;
font-size: 60px;
}

.heading-caption{
margin: 0;
font-size: 12px;
}
.circle-1{
border-radius: 50%;
top: 100px;
position: absolute;
padding: 100px;
background-image: linear-gradient(orange, pink);
}
/*--screenshots carousel--*/
.slideshow-buttons-container{
display: flex;
justify-content: space-around;
}
.slideshow-button {
  font-family: 'Krona One', sans-serif;
display: flex;
flex-direction: column;
}
.slideshow-button>button{
  font-family: 'Krona One', sans-serif;
}
i.fa.chevron-circle-right,i.fa.chevron-circle-left{
font-size: 40px;
}
.screenshot-caption{
font-size: 14px;
}

.captions-container{
text-align:center;
margin: auto;
height: 45px;
}
.banner{
  background-color: rgba(56, 174, 255, .7);
  padding: 20px;
  margin-bottom: 40px;
}
.category-label{
  text-align: center;
  font-size: 9px;
}
.logo-link.categories{
  width: 33%;
}
/*--flip animation for project titles...--*/
.app-one-flip{

margin-bottom: 20px;
  background-color: none;

height: 100px;
  font-size: 20px;
  color: #fff;
  perspective: 1000px;
  }
.app-one-inner{
position: relative;
width: 100%;
height: 100%;
text-align: center;
transition: transform 0.8s;
transform-style: preserve-3d;
}

.app-one-flip:active .app-one-inner {
transform: rotateY(180deg);
}
.app-one-flip:hover .app-one-inner {
transform: rotateY(180deg);
}
.app-one-flip:focus .app-one-inner {
transform: rotateY(180deg);
}
.app-one-front, .app-one-back {
position: absolute;
width: 100%;
height: 100%;
-webkit-backface-visibility: hidden; /* Safari */
backface-visibility: hidden;
}

.app-one-front{
background-color: transparent;
color: white;
border-radius: 20px;
}

.app-one-back {
background-color: transparent;
font-size: small;
color: white;
border-radius: 20px;
transform: rotateY(180deg);
position: relative;
top: 40px;

}
  .app-one-flip>p{
  font-size: medium;

  margin-left: 10px;
  }
  .app-icons{
      display: flex;
      justify-content: space-around;
  }
 

/*--information about app in projects section--*/
.app-left-right{
  display: flex;
  justify-content: space-around;
}
.app-one{

margin: 20px;
font-size: 20px;
color: #fff;


}
.app-one>h2{
font-size: 20px;
margin-top: 20px;
margin-left: 11px;
}
.app-one>p{
margin-left: 10px;
margin-bottom: 5px;
font-size: 14px;


  }
.app-one>h3 {

  font-size: 15px;
  margin: 0;

}

h3{
  text-align: center;
}
.app-left>h4{
  text-align: center;
}
  .app-one>ul,li{
      font-size: 12px;
      text-align: start;
  }
  .app-links-container{
    width: 50%;
    text-align: center;
  }
  .logo-links{
      display: flex;
      justify-content: space-around;
  }
  .logo-link>a{
      color: white;
      text-decoration: none;
      font-size: 12px;
  }
.fa-github-alt{
    font-size: 30px;
}
.front-end-list{
  line-height: 1.4em;
}
.back-end-list{
  margin-left: 11px;
}

.contact{
margin-top: 100px;
margin-bottom: 40px;
background: rgb(255, 165, 0, .5);
padding: 20px;
border-radius: 20px;
box-shadow: 5px 5px 5px rgb(173, 113, 1);

}
.contact>h2{
text-align: center;
color:white;
letter-spacing: .3em;
font-size: 40px;
}
ul.contact-me-left{
list-style: none;
}
.contact-me-left>li{
margin-bottom: 20px;
font-size: 15px;
}
.contact-me-left>li>a{
color: black;
text-decoration: none;
}
.contact-me-left-right{
display: flex;

}
.contact-me-img{
margin-top: 50px;;
margin-left: 40px;
}
.contact-icon{
font-size: 25px;
margin-right: 20px;
}
@media screen and (min-width: 550px) {
.projects-left{
  width: 50%;
}
.mobile-photo-me{
  display: none;

}
.mobile-photo-books{
  display: none;
}
.last-para-mobile{
  display: none;
}
}


@media screen and (max-width: 550px) {
  .app-left-right{
  flex-direction: column;
  }
  .about-me-content{
  height:120%;
  margin-bottom: 250px;
  }
  .app-main{
  width:310px;
  }
  .last-para{
    display: none;
  }
  .photo-circles{
    display: none;
  }
  .photo-container{
    text-align: center;
  }
  .app-links-container{
    width: auto;
  }
  .mobile-photo-me{
 border-radius: 50%;
  }
  .mobile-photo-books{
    border-radius: 50%;
    background-image: url('./images/books.jpeg');
    /*- photo by Greg Rakozy https://unsplash.com/@grakozy-*/
    background-size: 350px;
    background-size: 365px;
    background-position-y: 22px;
    background-position-x: -60px;
    width: 300px;
    height: 300px;
    margin: auto;
    background-color: rgb(255, 0, 0, .5);
    border-radius: 50%;
  }
.last-para-mobile{
margin: auto;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: cadetblue;
}
.last-para-mobile>p{
padding: 20px;
position: relative;
bottom: 240px;
line-height: 1.4em;
text-align: center;
}
.mobile-photo-mission-control{

  width: 300px;
  height: 300px;
  background-image: url('./images/mission-control.jpeg');
  /*-photo by Sieuwert Otterloo https://unsplash.com/ -*/
  background-size: 350px;
  background-size: 395px;
  background-position-y: 30px;
  background-position-x: -65px;

  background-color: rgb(40, 141, 236, .5);
  border-radius: 50%;
}
.mobile-photo-mission-control>p{
padding: 47px;
line-height: 27px;

}
  .creative-circle-red{
  background-size: 153px;
  background-position-y: 0px;
  background-position-x: -19px;
  top: 650px;
  width: 100px;
  height: 100px;
  }
  .creative-circle-blue{
  background-size: 153px;
  background-position-y: 0px;
  background-position-x: -19px;
  top: 1250px;
  width: 100px;
  height: 100px;
  }
  .projects-right{
  width: auto;
  }
  label{
  font-size: 12px;
  }
  .projects-header{
  margin: 0;
  }
  .b4>h2>p{
  height: 40px;
  width: 40px;
  font-size: .7em;

  }
  .b2{
  padding: 20px 0 20px 0; 
  font-size: .7em;
  }
  .b3-left-right{
  font-size: .7em;
  left: 0;
  }

  button{
  width: 120px;
  }
  .back-end-list{
    margin-left: auto;
  }
  .logo{
  width: 80px;
  position: relative;
  right: 3px;
  }
  .contact>h2{

  letter-spacing: .3em;
  font-size: 15px;

  }
  .contact-me-img{

  width: 100px;
  }
  .contact-me-left>li{

  margin-bottom: 20px;
  font-size: 12px;

  }
  .contact-icon{
  font-size: 15px;
  margin-right: 20px;
  }
  .contact-me-left-right{
  display: flex;
  flex-direction: column;

  }
  .contact-me-right{
  text-align: center;
  }
  .contact-me-img{
    margin-top: auto;;
    margin-left: auto;
    }
    

}

@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
  .about-me-content{
    height:140%;
    margin-bottom: 400px;
  }
  button{
  width: 100px;
  }
  label{
    font-size: .4em;
  }
  .box-one{
  width: 315px;
  margin-right: 15px;
  }
  .projects-header{
  width: 41%;
  height: 98px;
  margin: auto;
  background-color: rgb(235, 225, 91);
  box-shadow: 5px 5px 4px rgb(151, 145, 58);
  }
  .projects-header>p{
  display: contents;
  }

}