.banner.wekend{
  background-color: rgb(255, 255, 255, .7);
  color: black;
  box-shadow: 0px 5px 15px rgb(119, 118, 118);
    }
    .header-wekend, .flip-wekend>p{
        color: black;
    }

    
